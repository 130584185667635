import React, { Component } from "react";
import ContextMenu from "./contextmenu";
import Folder from "./folder";
import File from "./file";
import {withContext} from '../context/context';
import { subscribe, publish } from "../event/event"

class Explorer extends Component {
    list = {}
    constructor(props) {
        super(props);
        this.onFolderClick = this.onFolderClick.bind(this);
        this.handleBack = this.handleBack.bind(this);
        this.handleNext = this.handleNext.bind(this);
        this.state = {
            cursor: 0,
            clickedItem: null,
            folderHistory: []
        }
        subscribe("logged_in", () =>  {
            this.props.context.services.apiService.refreshList()
                .then(json => json.json())
                .then((response) => {
                    this.list = response
                    this.setState({folderHistory: [this.list.entries]})
                })
        });
    }
    onFolderClick(item) {
        let nextCursor = this.state.cursor
        let nextFolderHistory = [...this.state.folderHistory].slice(0, nextCursor+1)
        nextFolderHistory.push(item.entries)
        nextCursor++
        this.setState({ folderHistory: nextFolderHistory, cursor: nextCursor })
    }

    handleBack() {
        this.setState((prevState) => ({ cursor: prevState.cursor - 1 }));
    }
    handleNext() {
        this.setState((prevState) => ({ cursor: prevState.cursor + 1 }));
    }

    render() {
        const currentFolder = this.state.folderHistory ? this.state.folderHistory.slice(this.state.cursor).at(0) : null
        if (!currentFolder) {
            return ("")
        }
        return(
            <div className={"explorer-container"}>
                <ContextMenu onAction={this.sendAction}/>
                <div className={"explorer"} onClick={(event) => { publish("explorer_click", event) }}>
                    <div className={"buttons"}>
                        <button onClick={this.handleBack} className={"button"+(this.state.cursor === 0 ? " disabled": "")}>Retour</button>
                        <button onClick={this.handleNext} className={"button"+(this.state.cursor === this.state.folderHistory.length-1 ? " disabled": "")}>Suivant</button>
                    </div>
                    <div className={"list"}>
                        { currentFolder.folders && currentFolder.folders.map((folder) => {
                            return <Folder item={folder} onItemClick={this.onFolderClick} onItemContextMenu={(event, folder) => publish("context_menu", {event: event, item: folder})}/>
                        })}
                        { currentFolder.files && currentFolder.files.map((file) => {
                            return <File item={file} onItemContextMenu={(event, file) => publish("context_menu", {event: event, item: file})}/>
                        })}
                    </div>
                </div>
            </div>
        )
    }
}

export default withContext(Explorer)