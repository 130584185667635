import './App.css';
import React from "react";
import Login from "./components/login";
import Explorer from "./components/explorer";
import DownloadBar from "./components/downloadbar";
import {AppProvider} from "./context/appcontext";

class App extends React.Component {
    render() {
        return (
            <AppProvider>
                <div className="App">
                    <Explorer isFocus={true} />
                    <Login/>
                    <DownloadBar/>
                </div>
            </AppProvider>
        );
    }
}


export default App;
