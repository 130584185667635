import React, {Component} from "react";
import {withContext} from "../context/context";
import {subscribe, unsubscribe} from "../event/event"
class Login extends Component {
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.state = {
            loggedIn: this.props.context.services.apiService.preCheckLogin()
        }
        // avoid stacking listener
        subscribe('logged_out', () => {this.setState({loggedIn: false})})
        unsubscribe('logged_out', () => {this.setState({loggedIn: false})})
    }
    handleSubmit(event) {
        event.stopPropagation()
        event.preventDefault()
        let body = JSON.stringify(Object.fromEntries(new FormData(event.target)))
        this.props.context.services.apiService.login(body).then((result) => {
            if (result) {
                this.setState({loggedIn: true})
            }
        })
    }
    render() {
        if (!this.state.loggedIn) {
            return (
                <div className={"popup"}>
                    <div className={"login"}>
                        <form onSubmit={this.handleSubmit}>
                            <label htmlFor={"username"}>Nom</label>
                            <input name={"username"} type={"text"}/>
                            <label htmlFor={"password"}>Mot de passe</label>
                            <input name={"password"} type={"password"}/>
                            <input name={"submit"} type={"submit"} value={"valider"}/>
                        </form>
                    </div>
                </div>
            );
        } else {
            return ("")
        }
    }
}
export default withContext(Login);