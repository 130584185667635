import React, {Component} from "react";
import {withContext} from '../context/context';
import { subscribe, publish } from "../event/event"
class ContextMenu extends Component {
    menuItem = [
        {title: "Télécharger", id: 0, action: "download"},
        {title: "Télécharger et supprimer", id: 1, action: "download_delete"},
        {title: "Supprimer", id: 2, action: "download_delete"},
        {title: "Renommer", id: 3, action: "rename"}
    ]
    constructor(props) {
        super(props);
        this.handleContextMenu = this.handleContextMenu.bind(this);
        this.hideMenu = this.hideMenu.bind(this);
        subscribe("context_menu", (event) => {
            this.handleContextMenu(event.detail.event, event.detail.item)
        })
        subscribe("explorer_click", (event) => {
            this.hideMenu(event.detail) //subevent
        })
        this.state = {
            event: null,
            selectedItem: null
        }
    }
    sendAction(action, item) {
        if (action.includes("download")) {
            this.download(item).then(() => {
                if (action.includes('delete')) {
                    this.sendAction("delete", item)
                }
            })
        }
        if (action === 'delete') {
            this.props.context.services.apiService.fetch('DELETE', '/file/' + item.id).then(
                () => {
                    publish("item_deleted", item)
                }
            )
        }
        if(action === "rename") {
            let name = prompt("Choisir le nouveau nom", '')
            this.props.context.services.apiService.fetch('POST', '/file/' + item.id, {name: name}).then(
                () => {
                    publish("item_renamed", item)
                }
            )
        }
    }

    download(item) {
        let tempItem = {...item}
        tempItem.uuid = crypto.randomUUID()
        tempItem.receivedLength = 0
        tempItem.completion = 0
        publish("item_download_start", tempItem)
        return this.props.context.services.apiService.fetch('GET', '/file/' + tempItem.id)
            .then(async resp => {
                const reader = resp.body.getReader();
                let receivedLength = 0;
                let chunks = [];
                while (true) {
                    const {done, value} = await reader.read();
                    if (done) {
                        tempItem.completion = 100
                        publish("item_download_update", tempItem)
                        let chunksAll = new Uint8Array(receivedLength);
                        let position = 0;
                        for(let chunk of chunks) {
                            chunksAll.set(chunk, position);
                            position += chunk.length;
                        }
                        return new Blob(chunks, {
                            type: resp.headers.get('content-type')
                        });
                    }
                    chunks.push(value);
                    receivedLength += value.length;
                    tempItem.receivedLength = receivedLength
                    tempItem.completion = Math.round(100 * (receivedLength / tempItem.size))
                    publish("item_download_update", tempItem)
                }
            })
            .then(blob => {
                const url = window.URL.createObjectURL(blob)
                const a = document.createElement('a')
                a.style.display = 'none'
                a.href = url
                a.download = tempItem.name
                document.body.appendChild(a)
                a.click()
                window.URL.revokeObjectURL(url)
            })
            .catch(e => console.log(e))
    }
    hideMenu(event) {
        if (this.state.event !== null) {
            event.preventDefault()
            event.stopPropagation()
            this.setState({event: null})
            return undefined
        }
    }
    handleContextMenu(event, selectedItem) {
        event.preventDefault()
        this.setState({event: event, selectedItem: selectedItem})
    }
    render() {
        if (this.state.event !== null) {
            return (
                <div style={{left: 10+this.state.event.clientX+"px", top: 10+this.state.event.clientY+"px"}} className={"contextMenu"}>
                    {this.menuItem.map(item => <div onClick={(event) => {
                        this.hideMenu(event)
                        return this.sendAction(item.action, this.state.selectedItem) }
                    } id={item.id} className={"menu-item"}>{item.title}</div>)}
                </div>
            );
        } else {
            return ('')
        }
    }
}

export default withContext(ContextMenu);