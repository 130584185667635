import React, { Component } from "react";
class DownloadingFile extends Component {
    unityLabels = ["oct", "Ko", "Mo", "Go"]
    render() {
        let unity = Math.floor(Math.log(this.props.file.size) / Math.log(1024))
        return (
            <div className={"downloadFile"}>
                {this.props.file.name}
                <div className={"completion-wrapper"}>
                    <div className={"completion"} style={{width: this.props.file.completion+'%'}}></div>
                    <div className={"percent"}>{this.props.file.completion}%</div>
                </div>
                <div className={"size"}>
                    {Math.round(this.props.file.receivedLength / (1024 ** unity) * 100) / 100}/
                    {Math.round(this.props.file.size /(1024 ** unity) * 100) / 100} {this.unityLabels[unity]}
                </div>
            </div>
        )
    }
}

export default DownloadingFile;