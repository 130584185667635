import Item from "./item";
class Folder extends Item {
    getImgSrc() {
        return "/folder.png"
    }
    getType() {
        return "folder"
    }
    handleClick() {
        this.props.onItemClick(this.props.item, this.props.type)
    }
}

export default Folder;