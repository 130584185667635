import React, { Component } from "react";
class ContextMenu extends Component {
    constructor(props) {
        super(props);
        this.handleContextMenu = this.handleContextMenu.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.state = {
            selected: false
        }
    }
    getImgSrc() {
        throw new Error("Not implemented yet in child class")
    }
    getType() {
        throw new Error("Not implemented yet in child class")
    }
    handleClick() {
        throw new Error("Not implemented yet in child class")
    }

    handleContextMenu(event) {
        this.props.onItemContextMenu(event, this.props.item)
    }
    render() {
        let imgSrc = this.getImgSrc()
        return (
            <div className={"item "+this.getType()+" "+(this.state.selected ? "selected" : "")}
                 onClick={this.handleClick}
                 onContextMenu={this.handleContextMenu}
                 data-item={this.props.item.name}>
                <img className={"item-image"} src={imgSrc} alt={this.props.type}/>
                <div className={"item-title"}>{this.props.item.name}</div>
            </div>
        )
    }
}

export default ContextMenu;