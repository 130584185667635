import Item from "./item";
import {withContext} from "../context/context";
class File extends Item {
    handleClick() {
        switch (this.props.item.extension) {
            case "mkv":
            case "avi":
            case "mp4":
                break;
            case "txt":
            case "nfo":
            case "md":
            case "xml":
            case "json":
                break;
            case "png":
            case "jpg":
            case "gif":
            case "jpeg":
            default:
                return

        }
    }
    getImgSrc() {
        switch (this.props.item.extension) {
            case "png":
            case "jpg":
            case "gif":
            case "jpeg":
                return this.props.context.services.apiService.getImageUrl(this.props.item)
            case "mkv":
            case "avi":
            case "mp4":
                return "/movie.ico"
            default:
                return "/file.png"
        }
    }

    getType() {
        return "file"
    }
}

export default withContext(File);