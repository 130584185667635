import { createContext } from 'react';
import ApiService from '../service/apiservice'

const services = {
    apiService: new ApiService()
};
const AppContext = createContext(services);
const { Provider } = AppContext;
const AppProvider = ({ children }) => {
    return <Provider value={{services}}>{children}</Provider>;
};
export { AppContext, AppProvider }