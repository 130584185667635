import React, { Component } from "react";
import DownloadingFile from "./downloadingfile";
import { subscribe } from "../event/event"
class DownloadBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            list: []
        }
        this.getItemKeyWithUuid = this.getItemKeyWithUuid.bind(this);
        this.handleDownloadStart = this.handleDownloadStart.bind(this);
        this.handleDownloadUpdate = this.handleDownloadUpdate.bind(this);
        subscribe('item_download_start', this.handleDownloadStart)
        subscribe('item_download_update', this.handleDownloadUpdate)
    }
    handleDownloadStart(event) {
        this.setState(previousState => ({
            list: [...previousState.list, event.detail]
        }))
    }

    handleDownloadUpdate(event) {
        this.setState(previousState => {
            let key = this.getItemKeyWithUuid(event.detail.uuid)
            previousState[key] = event.detail
            return {
                list: [...previousState.list]
            }
        })
    }

    getItemKeyWithUuid(uuid) {
        for (let key = 0; key < this.state.list.length; key++) {
            if (this.state.list[key].id === uuid) {
                return key
            }
        }
    }

    render() {
        return (
            <div className={"downloadBar"}>
                <h2>Téléchargement</h2>
                { this.state.list.map((file) => {
                    return <DownloadingFile file={file}/>
                })}
            </div>
        )
    }
}

export default DownloadBar;