import { publish } from "../event/event"
import data from '../data.json';
export default class ApiService {
    constructor() {
        this.backendUri = data.backendUri
    }

    preCheckLogin() {
        let token = localStorage.getItem('bearer')
        if (token) {
            this.signIn(token)

            return true
        }

        return false
    }
    isAuthenticated() {
        return this.token !== null
    }
    signIn(token) {
        localStorage.setItem('bearer', token)
        this.token = token
        publish("logged_in")
    }
    signOut() {
        localStorage.removeItem('bearer')
        this.token = null
        publish("logged_out")

    }
    download(id) {
        return this.fetch("GET", '/file/'+id)
    }
    rename(item, name) {
        let id = item.id
        if (item.size === undefined) { //unsure
            id += "?isFolder=1"
        }
        return this.fetch("POST", '/file/'+id, {name: name})
    }
    delete(item) {
        let id = item.id
        if (item.size === undefined) { //unsure
            id += "?isFolder=1"
        }
        return this.fetch("DELETE", '/file/'+id)
    }

    refreshList() {
        return this.fetch("GET", '/files/list')
    }

    login(body) {
        return fetch(this.backendUri+'/login', {
            method: "POST",
            headers: {
                'Content-type': 'application/json'
            },
            body: body
        })
            .then((response) => {
                if (response.status !== 200) {
                    throw new Error("" + response.status)
                }
               return response.json()
            })
            .then((response) => {
                this.signIn(response.token)

                return true
            })
            .catch((err) => {
                console.error(err)

                return false
            })
    }

    getImageUrl(item) {
        return this.backendUri+"/file/"+item.id;
    }

    async fetch(method, url, body) {
        return await fetch(this.backendUri + url, {
            method: method,
            headers: {
                'Content-type': 'application/json',
                'Authorization': "bearer " + this.token
            },
            body: body
        }).then((response) => {
            if (response.status !== 200) {
                throw new Error("" + response.status)
            }
            return response
        }).catch((err) => {
            if (err.message === "401") {
                this.signOut()
            } else {
                throw err
            }
        })
    }
}